<template>
  <div class="max-h-full">
    <company-logo />

    <div v-t="'login.welcomeTitle'" class="text-15 mt-24 text-gray-600 dark:text-gray-200" />

    <div v-t="'login.welcomeMessage'" class="text-22 mt-4 font-semibold text-gray-800 dark:text-gray-50" />

    <div class="mt-24 flex gap-16">
      <authenticate-provider-button provider="github" @provider-click="loginWithProvider" />

      <authenticate-provider-button provider="google" @provider-click="loginWithProvider" />
    </div>

    <div class="mt-24 w-full border-b-[0.0625rem] border-gray-200 text-center leading-[0.0625rem] dark:border-gray-600">
      <span v-t="'login.alternativeOption'" class="bg-white p-4 text-gray-500 dark:bg-black dark:text-gray-300" />
    </div>

    <bb-form class="mt-32" :validation-schema="validationSchema" @submit="loginWithEmail">
      <bb-input
        v-model.trim="email"
        name="email"
        :label="$t('login.form.email.label')"
        type="email"
        :placeholder="$t('login.form.email.placeholder')"
        autocomplete="email"
        class="mb-16"
      />

      <authenticate-password-input
        v-model="password"
        :placeholder="$t('login.form.password.placeholder')"
        :label="$t('login.form.password.label')"
        name="password"
        class="mb-16"
      />

      <nuxt-link
        :to="{ name: 'forget-password' }"
        class="text-13 text-under border-b-[0.0625rem] border-dashed text-gray-500 dark:text-gray-300"
      >
        {{ $t('login.forgotPassword') }}
      </nuxt-link>

      <bb-submit
        class="mt-16 w-full"
        :disabled="isEmailLoginRequestPending"
        :loading="isEmailLoginRequestPending"
        custom="primary"
      >
        {{ $t('login.form.submitText') }}
      </bb-submit>
    </bb-form>

    <div class="mt-40 text-center">
      <span v-t="'login.notRegisteredText'" class="text-gray-600 dark:text-gray-200" />

      <nuxt-link :to="{ name: 'signup' }" class="ml-4 text-blue-500">{{ $t('login.notRegisteredLink') }}</nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { object, string } from 'yup';

import type { Provider } from '../../types/authentication';

definePageMeta({
  layout: 'authentication',
  name: 'login',
});

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const validationSchema = object({
  email: string().required(t('login.form.email.validation.required')).trim(),
  password: string().required(t('login.form.password.validation.required')),
});

const email = ref('');
const password = ref('');

const isEmailLoginRequestPending = ref(false);

const client = useSupabaseClient();

const confirmPage = { name: 'auth.confirm', query: route.query };

const loginWithProvider = async (provider: Provider) => {
  const { error } = await client.auth.signInWithOAuth({
    provider,
    options: {
      redirectTo: window.location.origin + router.resolve(confirmPage).href,
    },
  });

  if (error) useNotificationBanner(t(useHandleSupabaseError(error)), 'error');
};

const loginWithEmail = async () => {
  isEmailLoginRequestPending.value = true;
  const { error } = await client.auth.signInWithPassword({ email: email.value, password: password.value });
  isEmailLoginRequestPending.value = false;

  if (error) useNotificationBanner(t(useHandleSupabaseError(error)), 'error');
  else await navigateTo(confirmPage);
};
</script>
